/*!
 * Vitality v1.3 (http://themes.startbootstrap.com/vitality-v1.1)
 * Copyright 2014 Start Bootstrap Themes.
 * Licensed at WrapBootstrap, visit WrapBootstrap to purchase a usage license for this theme (https://wrapbootstrap.com/)
 */

html,
body {
  height: 100%;
}

.img-centered {
  margin: 0 auto;
}

.content-row {
  margin-top: 30px;
}

section {
  padding: 100px 0;
}

section.bg-gray {
  background-color: #f5f5f5;
}

@media(max-width:767px) {
  section {
    padding: 75px 0;
  }
}

body {
  font-family: Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 300;
}

a {
  color: #ef4035;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 18px;
  line-height: 1.5;
}

p.lead {
  text-transform: uppercase;
  font-family: Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 24px;
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 900;
}

@media(min-width:481px) {
  h1 {
    font-size: 52px;
  }

  h2 {
    font-size: 50px;
  }
}

hr {
  height: 2px;
  margin: 15px auto;
  max-width: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

hr.left {
  margin: 15px auto 15px 0;
}

hr.right {
  margin: 15px 0 15px auto;
}

hr.colored {
  border-color: #ef4035;
}

@media(min-width:768px) {
  hr {
    margin: 20px auto;
    max-width: 200px;
  }

  hr.left {
    margin: 20px auto 20px 0;
  }

  hr.right {
    margin: 20px 0 20px auto;
  }
}

.text-primary {
  color: #ef4035;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
  border-radius: 0;
  color: #ef4035;
  background-color: #fff;
}

.navbar-default {
  background-color: #ef4035;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: rgba(255,255,255,.5);
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: rgba(255,255,255,.5);
}

.navbar-inverse {
  background-color: #222;
}

.navbar-default,
.navbar-inverse {
  border: 0;
  border-bottom: 1px solid rgba(255,255,255,.1);
}

.navbar-default .navbar-brand,
.navbar-inverse .navbar-brand {
  padding: 0 15px;
  text-decoration: none;
  color: #fff;
  opacity: 1;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.navbar-brand img {
  max-height: 61px;
}

.navbar-default .navbar-brand:hover,
.navbar-inverse .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-inverse .navbar-brand:focus {
  opacity: 1;
}

.navbar-default .navbar-nav li a,
.navbar-inverse .navbar-nav li a {
  color: #fff;
}

.navbar-default .navbar-nav li a:hover,
.navbar-inverse .navbar-nav li a:hover {
  color: rgba(255,255,255,.5);
}

@media(min-width:768px) {
  .navbar-default .navbar-nav>.active>a,
  .navbar-default .navbar-nav>.active>a:hover,
  .navbar-default .navbar-nav>.active>a:focus,
  .navbar-inverse .navbar-nav>.active>a,
  .navbar-inverse .navbar-nav>.active>a:hover,
  .navbar-inverse .navbar-nav>.active>a:focus {
    border-radius: 5px;
  }

  .navbar-fixed-top.navbar-expanded {
    padding: 30px 0;
  }

  .navbar-fixed-top {
    padding: 15px 0;
    -webkit-transition: padding .5s;
    -moz-transition: padding .5s;
    transition: padding .5s;
  }

  .navbar-fixed-top .navbar-brand:hover {
    opacity: .5;
  }

  .navbar-default.navbar-expanded,
  .navbar-inverse.navbar-expanded {
    border: 0;
    background: 0 0;
  }
}

.navbar a:focus {
  outline: 0;
}

.navbar .navbar-nav li a:focus {
  outline: 0;
}

header {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  padding: 100px 0;
  background: no-repeat center center;
  background-attachment: scroll;
  background-color: #222;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

header .intro-content {
  position: relative;
  text-align: center;
  color: #fff;
}

header .brand-name {
  margin-top: 15px;
  text-transform: uppercase;
  font-family: Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 55px;
  font-weight: 900;
  line-height: 55px;
}

header .brand-name-subtext {
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
}

header img {
  max-height: 150px;
  max-width: 150px;
}

header .scroll-down {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

header .scroll-down .btn {
  width: 50px;
  height: 50px;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 100%;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: normal;
  color: #fff;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

header .scroll-down .btn:hover {
  border-color: rgba(255,255,255,.5);
  outline: 0;
  color: rgba(255,255,255,.5);
}

@media(min-width:768px) {
  header {
    width: 100%;
    height: 100%;
    padding: 0;
    background-attachment: scroll;
  }

  header .intro-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin: auto;
    text-align: center;
    color: #fff;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  header .brand-name {
    font-size: 80px;
    line-height: 80px;
  }

  header .brand-name-subtext {
    font-size: 30px;
    line-height: 30px;
  }

  header img {
    max-height: 300px;
    max-width: 300px;
  }
}

@media(min-width:1025px) {
  header {
    background-attachment: fixed;
  }
}

header.video .overlay {
  z-index: 998;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #000;
}

header.video .intro-content {
  z-index: 999;
}

header.video .scroll-down {
  z-index: 999;
}

@media(min-width:768px) {
  header.video .overlay {
    opacity: .5;
  }
}

aside.cta-quote {
  padding: 100px 0;
  text-align: center;
  color: #fff;
  background: no-repeat center center scroll;
  background-color: #222;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

aside.cta-quote span.quote {
  display: block;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
}

@media(min-width:768px) {
  aside.cta-quote {
    padding: 150px 0;
    background-attachment: scroll;
  }

  aside.cta-quote span.quote {
    font-size: 36px;
    line-height: 38px;
  }
}

@media(min-width:992px) {
  aside.cta-quote {
    padding: 300px 0;
  }

  aside.cta-quote span.quote {
    font-size: 40px;
    line-height: 42px;
  }
}

@media(min-width:1025px) {
  aside.cta-quote {
    background-attachment: fixed;
  }
}

.testimonials {
  text-align: center;
}

.about-content {
  padding: 30px;
}

.floating-label-form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: .5em;
  border-bottom: 1px solid #e1e1e1;
}

.floating-label-form-group input,
.floating-label-form-group textarea {
  z-index: 1;
  position: relative;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  border-radius: 0;
  font-size: 1.5em;
  background: 0 0;
  box-shadow: none!important;
  resize: none;
}

.floating-label-form-group label {
  display: block;
  z-index: 0;
  position: relative;
  top: 2em;
  margin: 0;
  font-size: .85em;
  line-height: 1.764705882em;
  vertical-align: middle;
  vertical-align: baseline;
  opacity: 0;
  -webkit-transition: top .5s ease,opacity .5s ease;
  -moz-transition: top .5s ease,opacity .5s ease;
  -ms-transition: top .5s ease,opacity .5s ease;
  transition: top .5s ease,opacity .5s ease;
}

.floating-label-form-group:not(:first-child) {
  padding-left: 14px;
  border-left: 1px solid #e1e1e1;
}

.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1;
}

.floating-label-form-group-with-focus label {
  color: #ef4035;
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #e1e1e1;
}

footer.footer {
  padding: 50px 0 20px;
  color: rgba(255,255,255,.7);
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

footer.footer a {
  color: rgba(255,255,255,.7);
}

footer.footer a:hover {
  color: #fff;
}

footer.footer p {
  font-size: 22px;
}

footer.footer .contact-details {
  margin: 75px 0;
}

footer.footer .social {
  margin-bottom: 75px;
}

footer.footer .copyright p.small {
  font-size: 14px;
}

@media(min-width:1025px) {
  footer.footer {
    background-attachment: fixed;
  }
}

.btn-outline-light {
  border: 1px solid #fff;
  color: #fff;
  background: 0 0;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
  outline: 0;
  color: #ef4035;
  background: #fff;
}

.btn-outline-dark {
  border: 1px solid #222;
  color: #222;
  background: 0 0;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus {
  border-color: #ef4035;
  outline: 0;
  color: #fff;
  background: #ef4035;
}

.btn {
  padding: 12px 18px;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
}

.btn:focus,
.btn:active,
.btn.active {
  outline: 0;
}

.btn-full-width {
  padding: 50px 0;
  border: 0;
  border-radius: 0;
  text-transform: none;
  font-size: 26px;
  font-weight: 200;
  letter-spacing: normal;
  color: #fff;
  background-color: #222;
}

.btn-full-width:hover {
  color: #fff;
  background-color: #ef4035;
}

.btn-full-width:focus {
  color: #fff;
}

body.modern {
  font-family: Cardo,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}

body.modern h1,
body.modern h2,
body.modern h3,
body.modern h4,
body.modern h5,
body.modern h6 {
  text-transform: uppercase;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
}

body.modern p.lead {
  text-transform: uppercase;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
}

body.modern header .brand-name {
  text-transform: uppercase;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
}

body.vintage {
  font-family: Cardo,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}

body.vintage h1,
body.vintage h2,
body.vintage h3,
body.vintage h4,
body.vintage h5,
body.vintage h6 {
  text-transform: uppercase;
  font-family: Sanchez,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}

body.vintage p.lead {
  text-transform: uppercase;
  font-family: Sanchez,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}

body.vintage header .brand-name {
  text-transform: uppercase;
  font-family: Sanchez,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
}


body{ font-family: 'Open Sans', sans-serif !important; font-weight:400!important;}
.navbar-default.navbar-expanded, .navbar-inverse.navbar-expanded{ /*background-color:rgba(73,1,72,.2);*/background-image: linear-gradient( to right, #bf4748, #490148 50% ); padding:0px;}
.navbar-inverse{ background-image: linear-gradient( to right, #bf4748, #490148 50% ); }
.nav.navbar-nav{}
.navbar-default .navbar-nav li a{font-family: 'Open Sans', sans-serif; color:#310030; color:#fff; font-size:18px; font-weight:400}
.navbar.navbar-inverse.navbar-fixed-top .navbar-nav li a{ color:#fff;}
.navbar.navbar-inverse.navbar-fixed-top.navbar-expanded .navbar-nav li a{ color:#310030; color:#fff; font-family: 'Open Sans', sans-serif; font-weight:400; font-style:normal}
nav.navbar .navbar-brand{ height:auto}
nav.navbar .container{ /*padding-top:15px; padding-bottom:15px;*/ padding-top:0px; padding-bottom:0px;}
nav.navbar .container .navbar-nav li{ margin-left:30px; margin-right:30px;}
nav.navbar .container .navbar-nav li a{ padding-left:0px; padding-right:0px; padding-top:17px; position:relative}
nav.navbar .container .navbar-nav li a:after{ position:absolute; left:0px; bottom:0px; display:block; height:2px; width:20px; /*background-color:#582f57;*/ content:"";}
nav.navbar .container .navbar-nav li.active a{ color:#cd5c5c; background-color:transparent; }
nav.navbar .container .navbar-nav li.active a:after{ background-color:#cd5c5c;}
nav.navbar .container .navbar-nav li a:hover{ color:#cd5c5c; background-color:transparent;}
.navbar-fixed-top{ padding-top:5px; padding-bottom:5px;}
.navbar-fixed-top.navbar-expanded{ padding-top:15px; padding-bottom:15px;}

header{ background-color:transparent}

/*.navbar-fixed-top .container{ padding-top:5px!important; padding-bottom:5px!important;}*/

/*
font-family: 'Open Sans', sans-serif;;
font-family: 'Open Sans', sans-serif;
Playfair+Display:400,700|Roboto:300,400,500,700
*/

/* main banner img */
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active.left, .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .active, .carousel-fade .carousel-inner .next.left, .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .container{ position:relative; height:100%;}
.carousel-fade .carousel-caption{ position: relative; text-shadow:none;
  top: 50%; left:0px; text-align:left; width:100%; max-width:520px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);}
.carousel-fade .carousel-caption h3{  color: white;  text-shadow: -1px 0 #000000,0 1px #000000,1px 0 #000000,0 -1px #000000; font-family: 'Open Sans', sans-serif !important; font-weight:700; text-transform:uppercase; position:relative; margin-bottom:60px; font-size:40px!important;}
.carousel-fade .carousel-caption h3:after{ content:""; background:url(../img/title-bg-style-img-white.png) no-repeat; width:194px; height:16px; display:block; position:absolute; left:0px; top:55px;}
.carousel-fade .carousel-caption p{  color: white;  text-shadow: -1px 0 #000000,0 1px #000000,1px 0 #000000,0 -1px #000000; font-family: 'Open Sans', sans-serif; font-size:400; font-size:18px;}
.carousel-fade .carousel-indicators{ left:auto; right:5%; width:auto; bottom:auto; -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); top:50%}
.carousel-fade .carousel-indicators li{ display:block; margin-bottom:50px; width:50px; height:50px; text-indent:0px; background-color:transparent; border:0px; position:relative; z-index:2}
.carousel-fade .carousel-indicators li:before{ position:absolute; content:""; width:100px; height:100px; left:50%; top:50%; margin-left:-50px; margin-top:-50px; border:2px solid transparent; border-radius:100%; z-index:1}
.carousel-fade .carousel-indicators li:after{ position:absolute; content:""; width:150px; height:150px; left:50%; top:50%; margin-left:-75px; margin-top:-75px; border:2px solid transparent; border-radius:100%; z-index:1}
.carousel-fade .carousel-indicators li.active:before, .carousel-fade .carousel-indicators li.active:after{ display:block; border:2px solid #4b034a;}
.carousel-fade .carousel-indicators li.active:after{ display:none}
.carousel-fade .carousel-indicators li img{ width:100%; border-radius:100% }

.advertisers-section{ /*background:url(../img/advertisers-bg.jpg) no-repeat top center; background-size:cover; height:1416px;*/ position:relative; margin-top:-250px; z-index: 2 }
.advertisers-section .dragon{ background:url(../img/dragon.png) no-repeat; right:0%; top:-15%; position:absolute; width:425px; height:1281px;}
.advertisers-section .commandos{ background:url(../img/advertisers-bg_02.png) no-repeat; left:-10%; bottom:0px; position:absolute; width:867px; height:668px;}
.advertisers-section .container{ padding-left:75px; padding-top:195px; position:relative;}
.advertisers-section .container .txt{ width:100%; max-width:700px;}
.advertisers-section .container .txt p{ font-size:16px; font-family: 'Open Sans', sans-serif; font-size:400; padding:0 0 30px; margin:0px; color:#3e3d46}
.advertisers-section .container .txt p em{ color:#74bdff}
.advertisers-section .container .txt h3{ padding:0px; margin:0 0 80px; font-size:40px; color:#2e012d; font-family: 'Open Sans', sans-serif;; text-transform:uppercase; font-weight:700; position:relative; bottom:0px;}
.advertisers-section .container .txt h3:after{ content:""; background:url(../img/title-bg-style-img.png) no-repeat; width:194px; height:16px; position:absolute; left:0px; bottom:-40px;}
.advertisers-section .carousel-indicators{ left:auto; right:0px; bottom:auto; -webkit-transform: translateY(-50%);-ms-transform: translateY(-50%); transform: translateY(-50%); top:50%; width:auto; margin-left:-70px; }
.advertisers-section .carousel-indicators li{ background-color:#d2d2d3; width:10px; height:10px; margin:0 0 16px; display:block; position:relative; border:0px;}
.advertisers-section .carousel-indicators li.active{ background-color:#b94243}
.advertisers-section .carousel-indicators li.active:after{ background:url(../img/carousel-controller-border-img-red.png) no-repeat; width:22px; height:22px; position:absolute; left:50%; top:50%; margin-left:-11px; margin-top:-11px; content:""; }

.about-section{ padding:250px 0 100px; position:relative }
.about-section .game-character{ display:none; position:absolute; top:0px; padding-left: 70px; margin-top:-200px; width:390px; max-width: 100%}

.about-section .game-character img{ width:100%}
.about-section .video{ width:100%; max-width:740px; height:400px; margin:auto auto 150px; position:relative; box-shadow: 0px 15px 19px 0px rgba(0,0,0,.5)}
.about-section .video img{ width:100%}
.about-section .video .play{ background:url(../img/video-play-btn-img.png) no-repeat; width:142px; height:142px; position:absolute; left:50%; top:50%; margin-left:-71px; margin-top:-71px; cursor:pointer; background-size:100%}
.about-section .txt{ text-align:center; width:100%; max-width:740px; margin:auto}
.about-section .txt h3{ color:#2e012d; padding:0px; margin:0 0 78px; font-size:40px; font-family: 'Open Sans', sans-serif;; font-weight:700; text-transform:uppercase; position:relative}
.about-section .txt h3:after{ content:""; display:block; background:url(../img/title-bg-style-img.png) no-repeat; height:16px; width:194px; position:absolute; left:50%; margin-left:-97px; bottom:-30px;}
.about-section .txt p{ font-family: 'Open Sans', sans-serif; color:#3e3d46; font-size:18px; font-weight:400; padding:0px; margin:0px;}
.about-section #about_section_carousel .carousel-indicators{ left:auto; right:0px; bottom:auto; -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%); transform: translateY(-50%); top:50%; width:auto }
.about-section #about_section_carousel .carousel-indicators li{ background-color:#bcbdbf; width:10px; height:10px; margin:0 0 16px; display:block; position:relative}
.about-section #about_section_carousel .carousel-indicators li.active{ background-color:#ec8a8a}
.about-section #about_section_carousel .carousel-indicators li.active:after{ background:url(../img/carousel-controller-border-img.png) no-repeat; width:22px; height:22px; position:absolute; left:50%; top:50%; margin-left:-11px; margin-top:-11px; content:""; }

.advertising-section { height:1250px; position:relative; top:-250px; background-size:cover}
.advertising-section .intro-img{ text-align:center; margin-top:-170px; padding-bottom:110px}
.advertising-section .intro-img img{ width:100%; max-width:1000px;}
.advertising-section .txt{ max-width:890px; width:100%; margin:auto; text-align:center}
.advertising-section .txt h3{ color:#fff; padding:0px; margin:0 0 78px; font-size:40px; font-family: 'Open Sans', sans-serif;; font-weight:700; text-transform:uppercase; position:relative}
.advertising-section .txt h3:after{ content:""; display:block; background:url(../img/title-bg-style-img.png) no-repeat; height:16px; width:194px; position:absolute; left:50%; margin-left:-97px; bottom:-30px;}
.advertising-section .txt p{ font-family: 'Open Sans', sans-serif; color:#fff; font-size:18px; font-weight:400; padding:0px; margin:auto; width:65%;}
.advertising-section .top{ background:url(../img/red_top.png) repeat-x; height:81px;}
.advertising-section .bottom{ background:url(../img/red_bottom.png) repeat-x; height:81px;}
.advertising-section .mid{ background:url(../img/red_middle.png) no-repeat top center; background-size:cover; height:1300px; padding:250px 0}

.blue-curved-section{ padding:0px; /*background:url(../img/blue-curve-bg-img-new.png) no-repeat top center; background-size:cover; min-height:1503px; */position:relative; z-index:2}
.blue-curved-section .top{ background:url(../img/blue_curve_top_part.png) repeat-x; height:203px; border-bottom:2px solid #004e92}
.blue-curved-section .bottom{ background:url(../img/blue_curve_bottom_part.png) repeat-x; height:200px; border-top:2px solid #004e92}
.blue-curved-section .container{ padding-left:75px;}
/*.blue-curved-section .top{ background:url(../img/blue-curve-top-img.png) no-repeat top center; height:402px; background-size:cover}*/
.blue-curved-section .mid{ /*background-image:
    linear-gradient(
      #003d7a, #001642
    );*/ min-height:300px; padding:24% 0 100px; background:#004e92; padding:50px 0;}
.blue-curved-section .mid .container{ position:relative}


.blue-curved-section .mid #blue_curved_section_carousel .carousel-indicators{ left:auto; right:0px; bottom:auto; -webkit-transform: translateY(-50%);-ms-transform: translateY(-50%); transform: translateY(-50%); top:50%; width:auto }
.blue-curved-section .mid #blue_curved_section_carousel .carousel-indicators li{ background-color:#bcbdbf; width:10px; height:10px; margin:0 0 16px; display:block; position:relative; border:0px;}
.blue-curved-section .mid #blue_curved_section_carousel .carousel-indicators li.active{ background-color:#74bdff}
.blue-curved-section .mid #blue_curved_section_carousel .carousel-indicators li.active:after{ background:url(../img/carousel-controller-border-img-blue.png) no-repeat; width:22px; height:22px; position:absolute; left:50%; top:50%; margin-left:-11px; margin-top:-11px; content:""; }

.blue-curved-section .mid .txt{ width:100%; max-width:800px;}
.blue-curved-section .mid .txt h5{ font-size:24px; font-family: 'Open Sans', sans-serif;; color:#fff; padding:0 0 10px; margin:0px; text-transform:none }
.blue-curved-section .mid .txt p{ font-size:16px; font-family: 'Open Sans', sans-serif; padding:0 0 30px; margin:0px; color:#fff}
.blue-curved-section .mid .txt p em{ color:#fff}
/*.blue-curved-section .bottom{ background:url(../img/blue-curve-bottom-img.png) no-repeat top center; height:426px; position:relative; z-index:1}*/
.blue-curved-section .top .container{ position:relative; height:100%;}
.blue-curved-section h3{ padding:0px; margin:0 0 60px; font-size:40px; color:#fff; font-family: 'Open Sans', sans-serif;; text-transform:uppercase; font-weight:700; position:relative; bottom:0px;}
.blue-curved-section h3:after{ content:""; background:url(../img/title-bg-style-img-white.png) no-repeat; width:194px; height:16px; position:absolute; left:0px; bottom:-20px;}
.blue-curved-section #logos{ margin-top:75px;}
.blue-curved-section #logos li{ width:150px; text-align:center;}

.testimonials{ margin-top:-230px; position:relative; z-index:1}
.testimonials .top{ background:url(../img/testimonial-top-bg.png) repeat-x; height:81px;}
.testimonials .bottom{ background:url(../img/testimonial-bottom-bg.png) repeat-x; height:81px;}
.testimonials .mid{ background:url(../img/testimonial-mid-bg.png) no-repeat top center; background-size:cover; height:978px; padding:150px 0}
.testimonials .mid h3{ padding:0px; margin:0 0 80px; font-size:40px; color:#fff; font-family: 'Open Sans', sans-serif;; text-transform:uppercase; font-weight:700; position:relative; bottom:0px;}
.testimonials .mid h3:after{ content:""; background:url(../img/title-bg-style-img.png) no-repeat; width:194px; height:16px; position:absolute; left:50%; bottom:-40px; margin-left:-97px}
.testimonials #testimonials_carousel{}
.testimonials #testimonials_carousel .carousel-indicators{ position:static; width:auto; text-align:center; margin-left:0px;}
.testimonials #testimonials_carousel .carousel-indicators li{ margin:0 10px; width:100px; height:100px; border:0px; text-indent:0px; border:5px solid transparent}
.testimonials #testimonials_carousel .carousel-indicators li img{ width:100%}
.testimonials #testimonials_carousel .carousel-indicators li.active{ width:180px; height:180px; border:5px solid #922c48; border-radius:100%; background-color:transparent}
.testimonials #testimonials_carousel .item{ text-align:center}
.testimonials #testimonials_carousel .item .date{ color:#fff; font-size:14px; font-family: 'Open Sans', sans-serif; font-weight:400; display:block; padding:0 0 5px; }
.testimonials #testimonials_carousel .item .name{ color:#fff; font-family: 'Open Sans', sans-serif;; font-size:24px; font-weight:700; display:block; padding:5px 0 30px}
.testimonials #testimonials_carousel .item .rating{ display:block; padding:0 0 20px;}
.testimonials #testimonials_carousel .item .content-div{ width:100%; max-width:575px; margin:auto; padding:25px 0 0}
.testimonials #testimonials_carousel .item p{ padding:0px; margin:0px; color:#fff; font-size:16px; font-family: 'Open Sans', sans-serif; font-weight:400; position:relative}
.testimonials #testimonials_carousel .item p:before{ content:""; background:url(../img/commas-img.png) no-repeat; width:47px; height:37px; display:block; position:absolute; left:-60px; top:-40px;}

.contact-section{ background:url(../img/contact-bg.png) no-repeat bottom center; padding-bottom:650px;padding-top:50px;}
.contact-section .container{ padding-left:75px}
.contact-section form label{ color:#2f0743; font-size:16px; font-family: 'Open Sans', sans-serif; font-weight:400; padding-left:5px;}
.contact-section form .form-control{ border:0px; border-radius:0px; background-color:transparent; border-bottom:1px solid #c0b4c6; box-shadow:none; padding-left:5px; color:#b94243; font-size:16px; font-weight:400; padding-top:8px; padding-bottom:10px; height:auto}
.contact-section form .form-control:focus{ border-bottom-color:#b94243}
.contact-section form .btn-default{ color:#2f0743; font-size:20px; font-weight:400; font-family: 'Open Sans', sans-serif; text-transform:uppercase; background-color:#fff; border:1px solid #3d1850; border-radius:20px; width:200px; text-align:center; border-radius:50px;}
.contact-section form .btn-default:hover, .contact-section form .btn-default:focus{ background-color:#3d1850; color:#fff;}
.contact-section .title { color:#2e012d; padding:0px; margin:0 0 78px; font-size:40px; font-family: 'Open Sans', sans-serif;; font-weight:700; text-transform:uppercase; position:relative}
.contact-section .title:after{ content:""; display:block; background:url(../img/title-bg-style-img.png) no-repeat; height:16px; width:194px; position:absolute; bottom:-30px;}
.contact-section form .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #b94243;
}
.contact-section form .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #b94243;
}
.contact-section form .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #b94243;
}
.contact-section form .form-control:-moz-placeholder { /* Firefox 18- */
  color: #b94243;
}
.contact-section h3{ color:#b94243; font-size:18px; padding:0 0 10px; margin:0px; font-weight:700; text-transform:uppercase; font-family: 'Open Sans', sans-serif;;}
.contact-section p, .contact-section a{ color:#2f0743; font-size:16px; padding:0 0 25px; margin:0px; font-weight:400; font-family: 'Open Sans', sans-serif;}
.contact-section .address-info{ padding:60px 0 0}

footer{ color:#1a1a1a; font-size:14px; font-weight:400; font-family: 'Open Sans', sans-serif; padding-top:12px; padding-bottom:12px;}
footer .logo{ display:inline-block; margin-right:10px;}
footer .logo img{ }
footer a{ color:#1a1a1a; font-size:14px; font-weight:400; font-family: 'Open Sans', sans-serif; display:inline-block; position:relative}
footer a:before{ content:"|"; color:#1a1a1a; font-size:12px; font-weight:400; font-family: 'Open Sans', sans-serif; margin:0 10px;}
footer .col-md-3{ padding-top:15px;}
footer .col-md-3 a{ color:#898989; font-size:16px; display:inline-block; margin-right:10px}
footer .col-md-3 a:before{ display:none}
/*
font-family: 'Open Sans', sans-serif;;
font-family: 'Open Sans', sans-serif;
Playfair+Display:400,700|Roboto:300,400,500,700
*/


.carousel-fade .carousel-caption {
  /*text-shadow: 0 1px 4px rgba(0,0,0,.9);*/
  font-size: 17px
}
.carousel-fade .carousel-caption h3 {
  font-size: 30px;
  font-family: 'Lato', sans-serif;
}
html.body{ height:100%}
.carousel-fade.carousel{ height:100%}
.carousel-fade .carousel-inner{ height:100%;}
.carousel-fade .carousel-inner .item { height:100% }
.carousel-fade .carousel-inner .item img{ width:100%; height:auto}
#carouselFade .item:nth-child(1) {
  background:url(../img/shutterstock_329959085.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#carouselFade .item:nth-child(2) {
  background: url(../img/shutterstock_418449364.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#carouselFade .item:nth-child(3) {
  background: url(../img/stretched-2560-1440-593189.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.advertisers-section img {
  border: 0;
  width: 48%;
  padding-right: 20px;
}

@media (min-width:300px) and (max-width:740px){
  .about-section{ padding:100px 0 100px; position:relative; margin-top: -350px;}
  footer .logo{ display:block; margin:0px;}
  footer a, footer{ font-size:12px;}
  .contact-section .container{ padding-left:15px;}
  .blue-curved-section .container{ padding-left:15px;}
  .about-section .video .play{ width:70px; height:70px; margin-left:-35px; margin-top:-35px;}

  .advertising-section .txt h3{ font-size:30px;}
  .advertising-section .txt p{ width:100%}

  .carousel-fade .carousel-indicators{ display:none}
  .carousel-fade .carousel-caption{ -webkit-transform:translateY(0%); -ms-transform:translateY(0%); transform:translateY(0%);}
  .advertising-section{ height:auto;}
  .advertising-section .intro-img{ margin-top:-30px;}
  .advertisers-section .container{ padding-top:100px; padding-left:15px;}

  header{ padding:92px 0 0; height:700px;}
  section header {height:auto;}
  .about-section .game-character{ display:none}

  .testimonials #testimonials_carousel .carousel-indicators li, .testimonials #testimonials_carousel .carousel-indicators li.active{ width:75px; height:75px; margin:0 5px;}

  .blue-curved-section .mid{  }
  .advertisers-section .commandos{ display:none;}
  .advertisers-section .dragon { display:none;}
  .advertisers-section .container{ padding-bottom:50px;}
  .advertisers-section{ margin-top:-150px;}
  .blue-curved-section .bottom{ margin-top:-3px;}
  .carousel-fade .carousel-caption{ top:0px;}
  .testimonials .top{ border-bottom:2px solid #452a5d; height:78px;}
  .about-section .video{ height:260px;}
}

@media (min-width:768px) and (max-width:1024px){
  nav.navbar .container .navbar-nav li{ margin-left:15px; margin-right:15px;}
  .carousel-fade .carousel-indicators{ right:10%;}
  .carousel-fade .carousel-indicators li::after{ display:none}
  .contact-section form{ padding-right:0px;}
  .about-section .game-character{ display:none}
  .advertisers-section .container .txt{ max-width:450px;}
  footer, footer a{ font-size:12px;}
}

@media (min-width:1600px) and (max-width:3400px){
  .about-section .curve-area{ height:502px;}
}
@media (min-width:3400px) and (max-width:4400px){
  .about-section .curve-area{ height:1002px;}
}

.about-section .carousel-inner {
  height: 200px;
}

#publishers .carousel-inner {
  height: 500px;
}

#advertisers .carousel-inner {
  height: 400px;
}

@media(max-width:700px) {
  #publishers .carousel-inner {
    height: 700px;
  }
}

body {
  overflow-x: hidden;
}

.about-section h3{ padding:0px; margin:0 0 60px; font-size:40px; color:#000; font-family: 'Open Sans', sans-serif;; text-transform:uppercase; font-weight:700; position:relative; bottom:0px;}
.about-section h3:after{ content:""; background:url(../img/title-bg-style-img.png) no-repeat; width:194px; height:16px; position:absolute; left:0px; bottom:-20px;}

.advertisers-section h3{ padding:0px; margin:0 0 60px; font-size:40px; color:#000; font-family: 'Open Sans', sans-serif;; text-transform:uppercase; font-weight:700; position:relative; bottom:0px;}
.advertisers-section h3:after{ content:""; background:url(../img/title-bg-style-img.png) no-repeat; width:194px; height:16px; position:absolute; left:0px; bottom:-20px;}

#portal-features ol, ul {
  color: #fff;
}

#privacy p, #tos p {
  font-size: 14px;
  margin-bottom: 20px;
}

#privacy, #tos {
  color: black;
}

#privacy strong, #tos strong {
  font-size: 18px;
}

#privacy header h1, #tos header h1 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
}

.carousel-fade {
  background: #000;
}

/* Safari Fix */
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .advertising-section{-webkit-transform: translate3d(0, 0, 1px); transform: translate3d(0, 0, 1px); }
  nav {-webkit-transform: translate3d(0, 0, 2px); transform: translate3d(0, 0, 2px);}
  #cc-notification {-webkit-transform: translate3d(0, 0, 2px); transform: translate3d(0, 0, 3px);}

}

.sprite {
  background-image: url(../img/logos.png);
  background-repeat: no-repeat;
  display: block;
}

.sprite-disney {
  width: 137px;
  height: 61px;
  background-position: -5px -5px;
}

.sprite-doom {
  width: 81px;
  height: 47px;
  background-position: -5px -76px;
}

.sprite-paramount {
  width: 91px;
  height: 74px;
  background-position: -96px -76px;
}

.sprite-playstation {
  width: 62px;
  height: 49px;
  background-position: -152px -5px;
}

.sprite-universal {
  width: 115px;
  height: 60px;
  background-position: -5px -160px;
}

.grecaptcha-badge {
  display:none;
}

#frontSlide {
  display:inline;
}

#tosSlide, #privacySlide {
  display:none;
}